jQuery(".dropdown-menu").click(function (e) {
    e.stopPropagation();
  });

 
  
jQuery(function ($) {
	// Update the UI with the initial load
	updatePostCountUI(
		zenergi_ajax_params.currentCount,
		zenergi_ajax_params.totalCount,
		zenergi_ajax_params.postType
	);

	// Rest of your AJAX load more functionality...

	function updatePostCountUI(currentCount, totalCount, postType) {
		// Update the UI element with the current and total count
		var postCountText =
			"Showing " +
			currentCount +
			" out of " +
			totalCount +
			" " +
			postType;
		jQuery("#post-count").text(postCountText);
	}

	var ajaxLoading = false;
	jQuery("#zenergi-load-more-btn").on("click", function (e) {
		e.preventDefault();
		if (ajaxLoading) return;
		ajaxLoading = true;

		var paged = parseInt(jQuery(this).data("paged")) + 1;
		// Extract the post type from the body class
		var bodyClasses = jQuery("body").attr("class").split(/\s+/);
		var postType = bodyClasses
			.find(function (c) {
				return c.startsWith("post-type-archive-");
			})
			.split("-")
			.pop();

		console.log(postType);

		$.ajax({
			type: "post",
			url: zenergi_ajax_params.ajax_url,
			data: {
				action: "zenergi_load_more",
				paged: paged,
				nonce: zenergi_ajax_params.nonce,
				post_type: postType, // Pass the detected post type here
			},
			beforeSend: function () {
				// Add your loader image here
				jQuery("#zenergi-load-more-btn").text("Loading...");
			},
			success: function (response) {
				jQuery("#zenergi-load-more-btn").data("paged", paged);
				jQuery(".archive-grid").append(response.html);
				ajaxLoading = false;
				jQuery("#zenergi-load-more-btn").text("Load More");
				var newCount = jQuery(".archive-grid-resources").children().length;
				updatePostCountUI(
					newCount,
					zenergi_ajax_params.totalCount,
					zenergi_ajax_params.postType
				);
				if (response.no_more_posts) {
					jQuery("#zenergi-load-more-btn").prop('disabled', true); // Hide the button if there are no more posts
				}
			},
			error: function () {
				console.log("AJAX load more posts error.");
				ajaxLoading = false;
			},
		});
	});
});
  
  var swiper = new Swiper(".service-slider", {
    slidesPerView: 4,
    spaceBetween: 20,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      490: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      575: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      991: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      1199: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".service-button-next2",
      prevEl: ".service-button-prev2",
    },
  });
  
  var swiper = new Swiper(".service-slider2", {
    slidesPerView: 4,
    spaceBetween: 20,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      490: {
        slidesPerView: 1.4,
        spaceBetween: 50,
      },
      575: {
        slidesPerView: 1.8,
        spaceBetween: 20,
      },
      767: {
        slidesPerView: 2.4,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      991: {
        slidesPerView: 3.4,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      1199: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".service-button-next",
      prevEl: ".service-button-prev",
    },
  });
  var swiper = new Swiper(".team-cards", {
    slidesPerView: 4,
    spaceBetween: 20,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      490: {
        slidesPerView: 1.4,
        spaceBetween: 20,
      },
      575: {
        slidesPerView: 1.8,
        spaceBetween: 20,
      },
      767: {
        slidesPerView: 2.4,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      991: {
        slidesPerView: 3.4,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      1199: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".team-button-prev",
      prevEl: ".team-button-next",
    },
  });
  
  var swiper = new Swiper(".studie-slider", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    slidesPerGroupSkip: 1,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
    pagination: {
      el: ".pagination2",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".studie-button-next",
      prevEl: ".studie-button-prev",
    },
  });







  var swiper = new Swiper(".gallery-swiper", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    slidesPerGroupSkip: 1,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
    pagination: {
      el: ".pagination2",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".studie-button-next",
      prevEl: ".studie-button-prev",
    },
  });







  var swiper = new Swiper(".gal-swiper", {
    slidesPerView: 1,
    spaceBetween: 20,
    keyboard: {
      enabled: true,
    }, 
    breakpoints: {
      460: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 'auto',
        spaceBetween: 0
      },
    },
    navigation: {
      nextEl: ".studiev-button-next",
      prevEl: ".studiev-button-prev",
    },
  });




  





  
  var swiper = new Swiper(".events-slider", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      767: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1365: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".pagination3",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
  });
  var swiper = new Swiper(".value-slider", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      767: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 1.6,
        spaceBetween: 20,
      },
      1365: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 2.3,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".value-pagination",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".value-button-next",
      prevEl: ".value-button-prev",
    },
  });
  var swiper = new Swiper(".events-slider3", {
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      767: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 1.6,
        spaceBetween: 20,
      },
      1199: {
        slidesPerView: 1.8,
        spaceBetween: 20,
      },
      1365: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 2.6,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".pagination5",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".button-next4",
      prevEl: ".button-prev4",
    },
  });
  
  var swiper = new Swiper(".customer-slider", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    slidesPerGroupSkip: 1,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 60,
      },
      767: {
        slidesPerView: 1.4,
        slidesPerGroup: 1,
        spaceBetween: 60,
      },
      991: {
        slidesPerView: 1.9,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1199: {
        slidesPerView: 2.2,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 2.5,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".customer-pagination3",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".customer-button-next",
      prevEl: ".customer-button-prev",
    },
  });
  
  var swiper = new Swiper(".resource-slider", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    slidesPerGroupSkip: 1,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      769: {
        slidesPerView: 2.2,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 4.8,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".pagination4",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".next-slide",
      prevEl: ".prev-slide",
    },
  });
  
  var swiper = new Swiper(".related-slider", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    slidesPerGroupSkip: 1,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 30,
      },
      767: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1199: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".related-pagination4",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".related-next-slide",
      prevEl: ".related-prev-slide",
    },
  });
  
  var swiper = new Swiper(".studie-slider2", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    slidesPerGroupSkip: 1,
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 30,
      },
      767: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1199: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".pagination4",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".next-slide",
      prevEl: ".prev-slide",
    },
  });
  var slider = new Swiper(".product-slider", {
    slidesPerView: 1,
    spaceBetween: 20,
    allowTouchMove: false,

    loop: true,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
      767: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 3.2,
        spaceBetween: 20,
      },
      1365: {
        slidesPerView: 4.2,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 4.8,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".product-next-slide",
      prevEl: ".product-prev-slide",
    },
  });
  var thumbs = new Swiper(".mySwiper2", {
    spaceBetween: 10,
    slideToClickedSlide: true,
    loop: true,
    navigation: {
      nextEl: ".product-next-slide",
      prevEl: ".product-prev-slide",
    },
    thumbs: {
      swiper: swiper,
    },
  });
  
  jQuery("#msg-icon").click(function () {
    jQuery(".chat-box").toggleClass("box-show");
  });
  jQuery("#myTab button").on('click', function(e) {
    e.preventDefault();

    window.location.href = jQuery(this).attr('data-url');
});
  
  document.querySelectorAll("#myTab button").forEach(function (everyitem) {
      if (window.innerWidth > 768) {
        everyitem.addEventListener("mouseenter", function () {
            // Trigger a click event on the tab
            // everyitem.click();
            console.log('showitem');
            jQuery(this).tab('show');
        });

      }
  });

  
  document.addEventListener("DOMContentLoaded", function () {
    window.addEventListener("scroll", function () {
      if (window.scrollY > 50) {
        document.getElementById("navbar_top").classList.add("fixed-top");
        // add padding top to show content behind navbar
        var navbar_height = document.querySelector(".navbar").offsetHeight;
        console.log(navbar_height);
        document.body.style.paddingTop = navbar_height + "px";
      } else {
        document.getElementById("navbar_top").classList.remove("fixed-top");
        // remove padding top from body
        document.body.style.paddingTop = "0";
      }
    });
  });
  
  jQuery(".navbar-toggler").click(function () {
    jQuery("body").toggleClass("space");
  });
  
  jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop() > 600) {
		jQuery(".bottom-nav").addClass("newClass");
    } else {
		jQuery(".bottom-nav").removeClass("newClass");
    }
  });


  var matches = 0;
  jQuery('section').each(function() {

    if (jQuery(this).is('.journey-sec')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="journey-sec">Overview</a></li>');
	  } else if (jQuery(this).is('.accelerat-sec-process')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="accelerat-sec-process">Process</a></li>');
	  } else if (jQuery(this).is('.overview-sec')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="overview-sec">Overview</a></li>');
	  } else if (jQuery(this).is('.cards-sec-benefits')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="cards-sec-benefits">Benefits</a></li>');
	  } else if (jQuery(this).is('.resource-sec-acc')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="resource-sec-acc">Resources</a></li>');
	  } else if (jQuery(this).is('.servprod')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="servprod">Related products</a></li>');
	  } else if (jQuery(this).is('.resourcecasestudies')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="resourcecasestudies">Our experience</a></li>');
	  } else if (jQuery(this).is('.servprod')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="servprod">Products</a></li>');
	  } else if (jQuery(this).is('.testimsec')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="testimsec">Testimonials</a></li>');
	  } else if (jQuery(this).is('.resourcecasestudies')) {
	    matches++;
	    jQuery('.servicenav').append('<li><a href="#" class="' + (matches == 1 ? 'active' : '') + '" data-target="resourcecasestudies">Case studies</a></li>');
	  }
  });




  jQuery(document).ready(function() {
    jQuery('.servicenav a').click(function(e) {
        e.preventDefault(); // Prevent the default anchor behavior

        var targetClass = jQuery(this).data('target'); // Get the data-target attribute

        if (targetClass) {
            var $targetElement = jQuery('.' + targetClass).first(); // Find the first element with the matching class

            if ($targetElement.length) {
                jQuery('html, body').animate({
                    scrollTop: $targetElement.offset().top
                }, 0); // Scroll to the element
            }
        }
    });
});


jQuery(document).ready(function() {

    var acceptedCookies = Cookies.get('acceptedCookies');

    if (!acceptedCookies) {
        // jQuery('#staticBackdrop').modal('show');
    }

    jQuery('#staticBackdrop').on('hidden.bs.modal', function (e) {
        Cookies.set('acceptedCookies', 'true', { expires: 30 });
    });

    jQuery('div#staticBackdrop .btn').on('click', function() {
      Cookies.set('acceptedCookies', 'true', { expires: 30 });
    });
  });


if(jQuery('.statswiper').length){
var swiper = new Swiper(".statswiper", {
  spaceBetween: 30,
  slidesPerView: 1.1,
  navigation: {
    nextEl: '.bvswiper-button-next',
    prevEl: '.bvswiper-button-prev',
  },
  breakpoints: {
    768: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    992: {
      slidesPerView: 2,
      spaceBetween: 20,
    }
  }
});
}



if(jQuery('.hero-sec-home').length){
var swiper = new Swiper(".mySwiper", {
  spaceBetween: 30,
  speed: 1000,
  autoplay: {
      delay: 5000,
      disableOnInteraction: true,
  },
  effect: "fade", fadeEffect: { crossFade: true },
});
// window.onload = () => {
//   jQuery('#staticBackdrop').modal('show');
// }
}


jQuery(document).ready(function(){
      jQuery(window).scroll(function(){
          var scrollPosition = jQuery(window).scrollTop();
          var links = jQuery('.servicenav a');
  
          var activeFound = false;
          links.each(function(){
              var targetClass = jQuery(this).attr('data-target');
              var target = jQuery('.' + targetClass);
              if(target.length){
                  if(target.position().top <= scrollPosition && target.position().top + target.outerHeight() > scrollPosition){
                      links.removeClass('active');
                      jQuery(this).addClass('active');
                      activeFound = true;
                      return false; // Break the loop
                  }
              }
          });
  
          // If no section is active, activate the last one passed
          if (!activeFound) {
              var lastSectionPassed = null;
              links.each(function(){
                  var targetClass = jQuery(this).attr('data-target');
                  var target = jQuery('.' + targetClass);
                  if(target.length && target.position().top < scrollPosition){
                      lastSectionPassed = jQuery(this);
                  }
              });
              if (lastSectionPassed) lastSectionPassed.addClass('active');
          }
      });
  });









  //  On mobile only wait for click
  if (window.innerWidth < 768) {
    jQuery('button.nav-link[data-url]').on('click', function(e) {
      e.preventDefault();
      window.location.href = jQuery(this).attr('data-url');
    });
  }




  jQuery('a[href="#inviteModal"]').on('click', function(event) {
    event.preventDefault();
    jQuery('#inviteModal').modal('show');
  });

  jQuery('a[href="#formpopup"]').on('click', function(event) {
    event.preventDefault();
    jQuery('#exampleModal2').modal('show');
    // jQuery('.navbar-toggler').click();
  });

  jQuery(document).ready(function ($) {






	jQuery("#filter-upcoming").on("click", function () {
		fetchEvents("upcoming");
    jQuery('.activfilter').removeClass('activfilter');
    jQuery(this).addClass('activfilter');
	});

	jQuery("#filter-past").on("click", function () {
		fetchEvents("past");
    jQuery('.activfilter').removeClass('activfilter');
    jQuery(this).addClass('activfilter');
	});

	function fetchEvents(type) {
		$.ajax({
			url: zenergi_ajax_params.ajax_url,
			type: "POST",
			data: {
				action: "filter_events",
				type: type,
				nonce: zenergi_ajax_params.nonce, // Pass your nonce here for security
			},
			success: function (response) {
				jQuery(".archive-grid").html(response);
				jQuery("#post-count").hide();
				jQuery("#zenergi-load-more-btn").hide();
			},
		});
	}
});

jQuery(document).ready(function ($) {
	jQuery(".filter-news-category").on("click", function () {
		// get the id of the button clicked
		var category = jQuery(this).attr("id");
		fetchNews(category);
    jQuery('.activfilter').removeClass('activfilter');
    jQuery(this).addClass('activfilter');
	});


	function fetchNews(category) {
		$.ajax({
			url: zenergi_ajax_params.ajax_url,
			type: "POST",
			data: {
				action: "filter_news_category",
				category: category,
				nonce: zenergi_ajax_params.nonce, // Pass your nonce here for security
			},
			success: function (response) {
				jQuery(".archive-grid").html(response);
				jQuery("#post-count").hide();
				jQuery("#zenergi-load-more-btn").hide();
			},
		});
	}
});

jQuery(document).ready(function ($) {
	jQuery(".filter-case-study-category").on("click", function () {
		// get the id of the button clicked
		var category = jQuery(this).attr("id");
		fetchNews(category);
    jQuery('.activfilter').removeClass('activfilter');
    jQuery(this).addClass('activfilter');
	});

	function fetchNews(category) {
		$.ajax({
			url: zenergi_ajax_params.ajax_url,
			type: "POST",
			data: {
				action: "filter_case_study_category",
				category: category,
				nonce: zenergi_ajax_params.nonce, // Pass your nonce here for security
			},
			success: function (response) {
				jQuery(".archive-grid").html(response);
				jQuery("#post-count").hide();
				jQuery("#zenergi-load-more-btn").hide();
			},
		});
	}
});

jQuery(document).ready(function ($) {
	jQuery(".filter-resource-type").on("click", function () {
		// get the id of the button clicked
		var resource_type = jQuery(this).attr("id");
		fetchResources(resource_type);
    jQuery('.activfilter').removeClass('activfilter');
    jQuery(this).addClass('activfilter');
	});

	function fetchResources(resource_type) {
		$.ajax({
			url: zenergi_ajax_params.ajax_url,
			type: "POST",
			data: {
				action: "filter_resource_type",
				resource_type: resource_type,
				nonce: zenergi_ajax_params.nonce, // Pass your nonce here for security
			},
			success: function (response) {
				jQuery(".archive-grid-resources").html(response);
				jQuery("#post-count").hide();
				jQuery("#zenergi-load-more-btn").hide();
			},
		});
	}
});



if(jQuery('.stickinner').length){
  jQuery('.stickinner').stick_in_parent({
    offset_top: jQuery('.navbar').outerHeight() + 30,
    recalc_every: 1
  });
}



jQuery(window).on('load', function() {
  if(jQuery('.activfilter').length) {
    jQuery('.activfilter').each(function(){
      jQuery(this).click();
      // if(jQuery(this).attr('id') == 'filter-upcoming') {
      //   fetchEvents('upcoming');
      // } else if(jQuery(this).attr('id') == 'filter-past') {
      //   fetchEvents('past');
      // } else if(jQuery(this).closest('.filternews').length) {
      //   var category = jQuery(this).attr("id");
      //   fetchNews(category);
      // } else if(jQuery(this).closest('.filterresources').length) {
      //   var category = jQuery(this).attr("id");
      //   fetchResources(category);
      // } else if(jQuery(this).closest('.filterstudies').length) {
      //   var category = jQuery(this).attr("id");
      //   fetchNews(category);
      // }
    });
  }
});





var lotties = {
  "homepageherolottie": {
    container: jQuery('.homepageherolottie').length ? jQuery('.homepageherolottie')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: '/wp-content/themes/understrap-child-main/lottie/Zen 01.json' // the path to the animation json
  },
  "hedder": {
    container: jQuery('.hedder').length ? jQuery('.hedder')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: '/wp-content/themes/understrap-child-main/lottie/header.json' // the path to the animation json
  },
  "windmill": {
    container: jQuery('.windmill').length ? jQuery('.windmill')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/understrap-child-main/lottie/Zen 2.json' // the path to the animation json
  },
  "documents" : {
    container: jQuery('.documents:not(.floatsocial)').length ? jQuery('.documents:not(.floatsocial)')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/understrap-child-main/lottie/Zen 3.json' // the path to the animation json
  },
  "documentsauto" : {
    container: jQuery('.documents.floatsocial').length ? jQuery('.documents.floatsocial')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: '/wp-content/themes/understrap-child-main/lottie/Zen 3.json' // the path to the animation json
  },
  "newsletterv" : {
    container: jQuery('.newsletterv').length ? jQuery('.newsletterv')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/understrap-child-main/lottie/Zen 4.json' // the path to the animation json
  },
  "complyanim" : {
    container: jQuery('.complyanim').length ? jQuery('.complyanim')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/understrap-child-main/lottie/Comply.json' // the path to the animation json
  },
  "buyanim" : {
    container: jQuery('.buyanim').length ? jQuery('.buyanim')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/understrap-child-main/lottie/Buy.json' // the path to the animation json
  },
  "deliveranim" : {
    container: jQuery('.deliveranim').length ? jQuery('.deliveranim')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/understrap-child-main/lottie/Deliver.json' // the path to the animation json
  },
  "netzeroanim" : {
    container: jQuery('.netzeroanim').length ? jQuery('.netzeroanim')[0] : '', // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/understrap-child-main/lottie/Net Zero.json' // the path to the animation json
  }
};




if(jQuery('.homepageherolottie').length) {
  lottie.loadAnimation(lotties.homepageherolottie);
}

if(jQuery('.hedder').length) {
  lottie.loadAnimation(lotties.hedder);
}

if(jQuery('.windmill').length) {
  var wmill = lottie.loadAnimation(lotties.windmill);
  jQuery(window).on('scroll', function(){
    if(jQuery('.windmill').length) {
      if(jQuery('.windmill').offset().top < (jQuery(window).scrollTop() + jQuery(window).height() - 100)) {
        wmill.play();
      }
    }
  });
}


if(jQuery('.documents.floatsocial').length) {
  lottie.loadAnimation(lotties.documentsauto);
}


if(jQuery('.documents:not(.floatsocial)').length) {
  var documents = lottie.loadAnimation(lotties.documents);
  jQuery(window).on('scroll', function(){
    if(jQuery('.documents:not(.floatsocial)').length) {
      if(jQuery('.documents:not(.floatsocial)').offset().top < (jQuery(window).scrollTop() + jQuery(window).height() - 100)) {
        documents.play();
      }
    }
  });
}

if(jQuery('.newsletterv').length) {
  var newsletterv = lottie.loadAnimation(lotties.newsletterv);
  jQuery(window).on('scroll', function(){
    if(jQuery('.newsletterv').length) {
      if(jQuery('.newsletterv').offset().top < (jQuery(window).scrollTop() + jQuery(window).height() - 100)) {
        newsletterv.play();
      }
    }
  });
}


if(jQuery('.complyanim').length) {
  var complyanim = lottie.loadAnimation(lotties.complyanim);
  jQuery(window).on('scroll', function(){
    if(jQuery('.complyanim').length) {
      if(jQuery('.complyanim').offset().top < (jQuery(window).scrollTop() + jQuery(window).height() - 100)) {
        complyanim.play();
      }
    }
  });
}

if(jQuery('.deliveranim').length) {
  var deliveranim = lottie.loadAnimation(lotties.deliveranim);
  jQuery(window).on('scroll', function(){
    if(jQuery('.deliveranim').length) {
      if(jQuery('.deliveranim').offset().top < (jQuery(window).scrollTop() + jQuery(window).height() - 100)) {
        deliveranim.play();
      }
    }
  });
}

if(jQuery('.buyanim').length) {
  var buyanim = lottie.loadAnimation(lotties.buyanim);
  jQuery(window).on('scroll', function(){
    if(jQuery('.buyanim').length) {
      if(jQuery('.buyanim').offset().top < (jQuery(window).scrollTop() + jQuery(window).height() - 100)) {
        buyanim.play();
      }
    }
  });
}

if(jQuery('.netzeroanim').length) {
  var netzeroanim = lottie.loadAnimation(lotties.netzeroanim);
  jQuery(window).on('scroll', function(){
    if(jQuery('.netzeroanim').length) {
      if(jQuery('.netzeroanim').offset().top < (jQuery(window).scrollTop() + jQuery(window).height() - 100)) {
        netzeroanim.play();
      }
    }
  });
}

jQuery('.scrollupbtn').on('click', function(e) {
  e.preventDefault();
  jQuery('html, body').animate({
    scrollTop: 0
  }, 0);
});

// Use Object.keys to iterate over the lotties object
// Object.keys(lotties).forEach(function(key) {
//   var value = lotties[key];
//   if(jQuery('.'+key).length) {
//     lottie.loadAnimation(value);
//   }
// });



var swiper = new Swiper('.vmySwiper', {
  slidesPerView: 1,
  spaceBetween: 10,
  navigation: {
    nextEl: '.vswiper-button-next',
    prevEl: '.vswiper-button-prev',
  },
  breakpoints: {
    // when window width is <= 640px
    640: {
      slidesPerView: 2.5,
      spaceBetween: 30
    }
  }
});


jQuery('.tabpillitem').on('click', function(e) {
  e.preventDefault();
  jQuery('.tabpillitemactive').removeClass('tabpillitemactive');
  jQuery(this).addClass('tabpillitemactive');
  jQuery('.tabcontentitem-active').removeClass('tabcontentitem-active');
  jQuery('.tabcontentitem[data-serv="'+jQuery(this).attr('data-serv')+'"]').addClass('tabcontentitem-active');  

  jQuery('.segactive').removeClass('segactive');

  if(jQuery(this).attr('data-serv') == 'deliver') {
    jQuery('.segment3').addClass('segactive');
  } else if(jQuery(this).attr('data-serv') == 'buy') {
    jQuery('.segment1').addClass('segactive');
  } else if(jQuery(this).attr('data-serv') == 'comply') {
    jQuery('.segment2').addClass('segactive');
  }


});







jQuery(document).ready(function($){

  if($('.slider-navv').length) {

  jQuery('.slickcustomprev').on('click', function(e) {
    e.preventDefault();
    jQuery(this).closest('section').find('.slick-slider').slick('slickPrev');
  });

  jQuery('.slickcustomnext').on('click', function(e) {
    e.preventDefault();
    jQuery(this).closest('section').find('.slick-slider').slick('slickNext');
  });



  var sliderNav = $('.slider-navv');

  function updateAdjacentClasses() {
    // Remove previous classes
    sliderNav.find('.slick-slide').removeClass('prev-slide next-slide');
  
    // Find the current center slide
    var $centerSlide = sliderNav.find('.slick-slide.slick-center');
  
    // Add 'prev-slide' to the previous sibling of the centered slide
    $centerSlide.prev().addClass('prev-slide');
    // Add 'next-slide' to the next sibling of the centered slide
    $centerSlide.next().addClass('next-slide');
  }
  
  // Initialize the slider
  sliderNav.slick({
    slidesToShow: 5, // Show 5 slides at once
    slidesToScroll: 1,
    draggable: false,
    variableWidth: false,
    dots: false,
    adaptiveHeight: true,
    arrows: true,
    centerMode: true, // Center the active slide
    focusOnSelect: true, // Enable click on slide to navigate
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });
  
  // After initialization, update the adjacent classes
  sliderNav.on('init', function(event, slick) {
    updateAdjacentClasses();
  });
  
  // Before a slide change, update the adjacent classes
  sliderNav.on('afterChange', function(event, slick, currentSlide) {
    updateAdjacentClasses();
  });
  updateAdjacentClasses();
  
  sliderNav.on('beforeChange', function(event, slick, currentSlide, nextSlide){
    // Remove custom center class from all slides
    sliderNav.find('.slick-slide').removeClass('custom-center');
  
    // Calculate the next center index
    var nextCenterIndex = nextSlide + Math.floor(slick.options.slidesToShow / 2);
  
    // Add a custom class to the next center slide
    sliderNav.find('.slick-slide').eq(nextCenterIndex).addClass('custom-center');
  
    // Adjust previous and next slide classes
    sliderNav.find('.slick-slide').removeClass('prev-slide next-slide');
    var prevSlideIndex = nextCenterIndex - 1 >= 0 ? nextCenterIndex - 1 : 0;
    var nextSlideIndex = nextCenterIndex + 1 < slick.slideCount ? nextCenterIndex + 1 : 0;
    sliderNav.find('.slick-slide').eq(prevSlideIndex).addClass('prev-slide');
    sliderNav.find('.slick-slide').eq(nextSlideIndex).addClass('next-slide');
    updateAdjacentClasses();
  });
  




  }












  // sliderNav = $('.slider-nav');
  // sliderFor = $('.slider-for');

  // sliderFor.slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false, // Hide arrows
  //   fade: true,
  //   asNavFor: '.slider-nav' // Connect with navigation slider
  // });
  // sliderNav.slick({
  //   slidesToShow: 5, // Show 5 years at once
  //   slidesToScroll: 1,
  //   variableWidth: false,
  //   asNavFor: '.slider-for', // Connect with details slider
  //   dots: false,
  //   adaptiveHeight: false,
  //   centerMode: true, // Center the active year
  //   focusOnSelect: true // Enable click on year to navigate
  // });

  

  // sliderNav.on('afterChange', function(event, slick, currentSlide) {
  //   sliderNav.find('.slick-slide[data-slick-index] .yearslide').css({ "width": "43px", "height": "43px", "font-size": "0px" });
  //   sliderNav.find('.slick-slide[data-slick-index="' + (currentSlide - 1) + '"] .yearslide, .slick-slide[data-slick-index="' + (currentSlide + 1) + '"] .yearslide').css({ "width": "98px", "height": "98px", "font-size": "22px" });
  //   sliderNav.find('.slick-slide[data-slick-index="' + currentSlide + '"] .yearslide').addClass('slick-current').css({ "width": "200px", "height": "200px", "font-size": "38px" });
  // });






  function adjustSliderPadding() {
      const container = document.querySelector('.container');
      const sliders = document.querySelectorAll('.customer-slider, .resource-slider, .product-slider');

      if (container) {
          const containerOffset = container.getBoundingClientRect().left + 12;

          sliders.forEach(slider => {
              slider.style.paddingLeft = `${containerOffset}px`;
          });
      }
  }

  // Adjust padding on initial load
  adjustSliderPadding();

  // Adjust padding on window resize
  window.addEventListener('resize', adjustSliderPadding);



  $('.nzerobitmiddle').on('click', function() {
    jQuery('[data-serv="netzero"]').click();
  }); 

  $('.head-comp').on('click', function() {
    jQuery('[data-serv="comply"]').click();
  });


  $('.head-del').on('click', function() {
    jQuery('[data-serv="deliver"]').click();
  });


  $('.head-buy').on('click', function() {
    jQuery('[data-serv="buy"]').click();
  });


  
  jQuery('a[href="#timelinemodule').on('click', function(event) {
    event.preventDefault();
    
    jQuery('html, body').animate({
        scrollTop: jQuery('.time-slider:visible:first').offset().top - 100
    }, 0);
  });

  jQuery(document).on('click', 'a[href="#quoteform"]', function(e) {
    e.preventDefault();
      jQuery('html, body').animate({
          scrollTop: jQuery('.qformblock:visible:first').offset().top - 100
      }, 0);
  });

  jQuery(document).on('click', 'a[href="#servicesblock"]', function(e) {
      e.preventDefault();
      jQuery('html, body').animate({
          scrollTop: jQuery('.service-sec:visible:first').offset().top - 100
      }, 0);
  });



  function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  jQuery(document).on('click', 'a[href="#serviceproducts"]', function(e) {
      e.preventDefault();
      if(jQuery('.servprod:visible:first').length) {
        jQuery('html, body').animate({
            scrollTop: jQuery('.servprod:visible:first').offset().top - 100
        }, 0);
      } else {
        jQuery('html, body').animate({
            scrollTop: jQuery('.netzerosec:visible:first').offset().top - 100
        }, 0);
      }
  });

  jQuery('.modal').on('show.bs.modal', function (e) {
    if(jQuery(this).find('iframe').length && jQuery(this).is(':not(.noiframe):not(.disiframe)')) {
        var src = jQuery(this).find('iframe').attr('src');
        if (src.indexOf('?') > -1) {
          jQuery(this).find('iframe').attr('src', updateQueryStringParameter(src, 'autoplay', '1'));
        } else {
          jQuery(this).find('iframe').attr('src', src + '?autoplay=1');
        }
      }
  });

  

jQuery('.choose-sec-vid .image-holder').on('click', function() {
  jQuery(this).fadeOut();
  var src = jQuery(this).closest('.choose-sec-vid').find('iframe').attr('src');
        if (src.indexOf('?') > -1) {
          jQuery(this).closest('.choose-sec-vid').find('iframe').attr('src', updateQueryStringParameter(src, 'autoplay', '1'));
        } else {
          jQuery(this).closest('.choose-sec-vid').find('iframe').attr('src', src + '?autoplay=1');
        }
});

  jQuery('.modal').on('hide.bs.modal', function (e) {
    if(jQuery(this).find('iframe').length && jQuery(this).is(':not(.noiframe):not(.disiframe)')) {
    var src = jQuery(this).find('iframe').attr('src');
    jQuery(this).find('iframe').attr('src', updateQueryStringParameter(src, 'autoplay', '0'));
    }
  });

  

});







// jQuery('#myTab li').on('click', function(e) {
//   console.error(e);
// }); 


window.addEventListener('message', event => {
  if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      console.log('form ready launch');
      jQuery('.hspremessage').hide();
  }
});



jQuery(document).ready(function() {
  // Select all <a> tags that have a href ending with '.pdf'
  jQuery('a[href$=".pdf"]').attr('target', '_blank');
});




//  Check if a param exists with key "dclick", if it does, click the element [data-post-id="592"] with the same value as the param

function getParameterByName(name) {
  var url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


jQuery(window).on('load', function() {
  if (window.location.search.indexOf('dclick') > -1) {
    var dclick = getParameterByName('dclick');
    console.log(dclick);
    console.log('[data-post-id="'+dclick+'"]');
    // setTimeout(function() {
      jQuery('[data-post-id="'+dclick+'"]')[0].click();
    // }, 1000);
  }
});



jQuery('.prdgriddaparent').on('click', function(e) {

  //  Prevent default unless it's an A tag that's been clicked
  if(!jQuery(e.target).is('a')) {
    e.preventDefault();
      
    if(jQuery(this).is('.accopened')) {
      jQuery(this).removeClass('accopened');
    } else {
      jQuery('.prdgriddaparent.accopened').removeClass('accopened');
      jQuery(this).addClass('accopened');
    }
  }
});




jQuery('[data-moddlertrigger]').on('click', function(e) {
  e.preventDefault();
  var target = jQuery(this).attr('data-moddlertrigger');
  jQuery('[data-moddler="'+target+'"]').addClass('activemoddler');
});

jQuery('.binmodal').on('click', function(e) {
  e.preventDefault();
  jQuery(this).closest('.activemoddler').removeClass('activemoddler');
});








const counterUp = window.counterUp.default;

const callback = entries => {
    entries.forEach(entry => {
        const el = entry.target;
        if (entry.isIntersecting && !el.classList.contains('is-visible')) {
            counterUp(el, {
                duration: 2000,
                delay: 16,
            });
            el.classList.add('is-visible');
        }
    });
};

// Create a single IntersectionObserver instance
const IO = new IntersectionObserver(callback, { threshold: 1 });

// Select all elements with the class 'counter'
const elements = document.querySelectorAll('.counter');

// Observe each element
elements.forEach(el => IO.observe(el));
